@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  position: relative;
}

:root {
  --background: #1c1c1eff;
  --text-primary: #f2f2f7ff;
  --text-secondary: #007affff;
  --accent: #007affff;
  --border: #808080;
  --form-bg: #1c1c1eff;
}

[data-theme='dark'] {
  --background: #1c1c1eff;
  --text-primary: #f2f2f7ff;
  --text-secondary: #007affff;
  --accent: #007affff;
  --border: #808080;
  --form-bg: #1c1c1eff;
}



.App {
  background-color: var(--background);
  color: var(--text-primary);
  justify-content: center;
  height: 95vh;
  display: flex;
  flex-direction: column;
  font-size: .8rem;
  transition: all .5s;
}

.Appz {
  height: 10vh;
}

a {
  color: var(--text-secondary);
  font-family: 'Poppins', sans-serif;
}

h1 {
  text-align: center;
  margin: -6rem 0 2rem;
  font-size: 48px;
}

h2 {
  text-align: center;
  padding: 1rem;
}

.theme-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 28px
}

.theme-toggle .theme-icon {
  font-size: 1.25rem;
}

.theme-toggle .icon {
  font-size: 2rem;
  cursor: pointer;
}

.container {
  /* display: flex; */
  /* flex-direction: column;  */
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 400px;
  margin: auto;
  box-shadow: 0px 0px 6px var(--border);
  border-radius: 20px;
  background-color: var(--form-bg);
}

.input-and-button {
  display: flex;
  flex-direction: row;
}

.result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-copy {
  width: 65px;
  background-color: crimson;
  border-color: crimson;
}

.result-text {
  font-weight: bold;
  text-decoration: none;
}

/* form
{
  width: 300%;
} */

/* .button-and-input{
  display: flex;
  justify-content: center;
  align-items: center;
} */


input {
  padding: 8px;
  margin: 8px 0;
  margin-right: 4px;
  border-radius: 6px;
  border: 1px solid var(--border);
  background: var(--background);
  color: var(--text-primary);
}

button {
  background-color: var(--accent);
  border: 1px solid var(--accent);
  color: #fff;
  margin: 8px 0;
  margin-left: 4px;
  padding: 8px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width:478px) {
  .login {
    width: 95%;
  }
}


/* .containerr {
  text-align: center;
} */

.nav {
  background-color: #1c1c1eff;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  height: 5vh;
}

.site-title {
  display: block;
  font-size: 2em;
  font-weight: bold;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

/* .nav li.active {
  background-color: #555;
} */

.nav li:hover {
  text-decoration: underline;
}


.input-form {
  width: 100%
}

.input-form {
  text-align: start;
}

.btn {
  display: flex;
  justify-content: center;

}

.larger-btn {
  width: 33%;
}

/* .containerr {
  display: flex;
  flex-direction: row;
} */

.containerrr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom {
  font-size: 24px;
}

.linkz {
  display: flex;
  flex-direction: row;

}

.linkshort {
  font-size: 20px;
  margin: 1.5rem;
}

.linkz2 {
  display: flex;
  flex-direction: column;
}

.biglink {
  padding: 1.5rem;
  width: 500px;
  display: flex;
  box-shadow: 0px 0px 6px var(--border);
  border-radius: 20px;
  background-color: var(--form-bg);
  margin: 1.5rem;
}

.proutgauche {
  padding-right: 1rem;
}